import React, { useState } from 'react';

const LandingPage = () => {
  const [videoExpanded, setVideoExpanded] = useState(false);
  const toggleVideoSize = () => {
    setVideoExpanded(prev => !prev);
  };

  return (
    <div className="landing-container">
      <div className="logo-container">
        {/* Logo à esquerda */}
        <img 
          src="/logo-enem-app-color.png" 
          className="logo" 
          alt="Enem App Logo" 
        />
        {/* Botão Login à direita */}
        <a href="/home" className="button-login">
          Login
        </a>
      </div>

      {/* Mantivemos a landing-header caso queira algo futuro,
          mas removemos o conteúdo vazio */}
      <div className="landing-header">
      </div>

      <div className="landing-main">
        <div className="landing-text">
          <h1>O melhor app para corrigir provas anteriores do ENEM</h1>
          <p>A forma mais eficiente para priorizar seus estudos.</p>
          <p>
            Todos sabemos que a melhor forma de estudar para o ENEM é treinar com provas anteriores.
            Agora você tem uma ferramenta que analisa seus resultados e direciona seus estudos.
          </p>
          <ul>
            <li>Corrija 12 provas (dos últimos 6 anos)</li>
            <li>Veja seu resultado</li>
          </ul>
          <h3>Como Funciona:</h3>
          <ol>
            <li>Baixe uma prova anterior</li>
            <li>Faça a prova</li>
            <li>Preencha suas respostas</li>
            <li>Veja seus resultados</li>
          </ol>
          <p>
            <strong>Valores:</strong> você paga uma vez só e tem acesso vitalício, 
            não é uma assinatura. O acesso custa R$29,90 e pode ser parcelado em 5x de R$6,75.
          </p>
          <div className="landing-cta">
            <a href="/home" className="button-landing">Conheça o app</a>
          </div>
        </div>

        <div className="landing-video">
          <video 
            controls 
            onClick={toggleVideoSize} 
            className={videoExpanded ? 'expanded' : ''}
          >
            <source src="/enem-app.mp4" type="video/mp4" />
            Seu navegador não suporta o elemento de vídeo.
          </video>
        </div>
      </div>

      <footer className="landing-footer">
        <p>
          Contato: contato@kodanik.tech | © Copyright -
          todos os direitos reservados - 2025
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;
